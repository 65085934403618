import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "청년 고립, 청년 500명에게 물어보다.",
  "slug": "data15",
  "date": "2022-07-16T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb15.png",
  "featuredImg": "../images/featured/featured15.png",
  "summary": "고립을 경험한 청년을 만나 이들이 어떤 경로로 고립됐고 어떻게 극복했는지 살펴봤다.",
  "tags": ["정책", "청년", "고립"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`학교폭력, 가족과의 갈등, 입시 실패, 해고, 가까운 사람의 죽음 등 청년이 고립 상태를 경험한 이유는 다양했다. 2년 넘게 지속된 코로나19는 아슬아슬하게 버텨 온 청년들의 일상을 흔들었다. 일자리가 사라지고 대면 활동이 줄어든 게 컸다.`}</p>
    <p>{`주변의 연락이 뜸해져 많은 청년이 초반에는 정서적으로 고립감을 느끼다가 서서히 집 밖을 나가지 않으면서 물리적 고립의 소용돌이에 빠져들었다. 일부 청년은 고립 기간이 길어져 사회적 관계가 끊어진 ‘은둔 청년’이 됐다.`}</p>
    <h2>{`연령 낮을수록 코로나가 고립에 영향`}</h2>
    <p>{`공공의창·서던포스트와 함께 지난 6 ~ 13일 만 20 ~ 39세 청년 500명을 대상으로 한 온라인 설문조사 결과를 보면 응답자의 연령이 낮아질수록 코로나19가 고립을 심화시키는 데 영향을 미쳤다는 비율이 높게 나타났다. 20 ~ 24세 여성의 경우 이 비율이 85.5%까지 치솟았다.`}</p>
    <h2>{`물리적 고립 경험, 여성>남성`}</h2>
    <p>{`물리적 고립을 경험했다는 응답은 남성(47.1%)보다 여성(53.1%)이 더 많았다. 다만 1년 이상 고립 기간을 겪은 응답자 중에선 남성(14.7%)이 여성(11.0%)보다 많았다.`}</p>
    <p>{`연령대별로는 30 ~ 34세의 경우 ‘1개월 이내 고립’(32.8%)보다 ‘1개월 이상 ~ 3개월 이내 고립’(39.7%)이 더 높게 나왔다. 특히 30 ~ 34세 남성은 1년 이상 고립 비율도 18.5%에 달했다.`}</p>
    <h2>{`15% “대화 나눌 상대 없다”`}</h2>
    <p>{`깊은 대화가 가능한 가족이나 친구·지인이 있는지를 묻는 질문에 ‘3명 이상’이라고 응답한 비율은 38.9%에 달했다. ‘1명’이라는 응답은 21.4%, ‘없다’는 15.2%였다. 깊은 대화가 가능한 주변 사람이 없다는 응답은 20대(12.0%)보다 30대(18.3%)가 더 많았다. 도시 규모가 커질수록(농어촌→중소도시→대도시→서울) 주변에 대화할 사람이 없다는 응답 비율이 높아지는 경향도 보였다.`}</p>
    <p>{`고립의 원인에 사회구조적 영향이 있는지를 묻는 질문에선 ‘그렇다’는 답변이 76.1%에 달했다. 경쟁 사회가 고립을 부추긴다는 뜻으로 해석된다. 그러면서도 응답자 중 가장 많은 26.7%는 고립의 내적 원인으로 성격 등 개인 문제를 꼽았다는 점이 눈에 띈다.`}</p>
    <p>{`취업을 준비할 시기인 25 ~ 29세에서만 ‘실패 경험’(26.5%)이 성격 등 개인 문제(19.6%)보다 높게 나왔다. 조사를 진행한 서던포스트 정우성 대표는 “정부 차원의 예방·해결책이 필요한데 오히려 청년들이 고립을 개인의 문제로 인식하고 있었다”고 했다.`}</p>
    <p>{`고립 해결에 가장 필요한 것으로 가족이나 친구의 관심·격려를 꼽은 비율이 33.0%로 가장 많았다. 심리 상담 프로그램(29.1%), 경제적 지원(18.8%), 공동체 참여 기회(14.9%) 등이 뒤를 이었다.`}</p>
    <p>{`2년간 고립 상태에 빠졌던 김선호(가명)씨는 “답답해서 생각이나 마음을 정리할 때 함께 소통할 사람이 필요했다”고 했다. 13년 동안 고립을 겪은 김자영(가명)씨도 “고립 기간이 길어질수록 점점 더 사람을 믿지 못했다”며 “상담 치료를 받으면서 가족과 대화를 하려고 노력했고 나중에는 가족의 이해와 지지가 큰 도움이 됐다”고 말했다.`}</p>
    <h2>{`고립의 원인은 무엇이라고 생각하나`}</h2>

    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <h6>{`*공공의창과 서던포스트가 자료를 제공하고 2022년 7월 17일 서울신문을 통해 발행된 기사입니다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      